@import "normalize.css";
@import "orderUtilities.css";
/* Hiding scroll bar in edge,IE 10/11 */

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  margin: 0;
  font-family: "Graphik", "Graphik Medium", "Graphik Semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-variant: none;
  background-color: #f5f7fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body,
#root {
  height: 100%;
  overflow: auto;
}
.ellipsis {
  /* Required for text-overflow to do anything */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.wordBreakAll {
  word-break: break-all;
}

.wordBreak {
  word-break: break-word;
}

.hidden {
  /* utility to hide element (not in use) for now */
  display: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
div::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
div {
  -ms-overflow-style: none;
}
.hiddenOverFlowText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.min-h-100 {
  min-height: 100%;
}

.MuiDrawer-paper {
  visibility: visible !important;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.border-box {
  box-sizing: border-box;
}

.overflow-auto {
  overflow: auto;
}

.width-fill-available {
  width: -webkit-fill-available;
}

.show-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: initial;
  border-radius: 20px;
}

.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #cad2e4;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  display: initial;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #bcbec1;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.font-weight-500 {
  font-weight: 500;
}

/* using for pop up search component */
div[data-placement*="bottom-start"],
div[data-placement*="top-start"] {
  z-index: 6000 !important;
}

/* using for notify component */
.Toastify__toast-container .Toastify__toast {
  min-height: 56px;
  border-radius: 8px;
}

.Toastify__toast-icon {
  padding-left: 4px;
}

/* Remove chrome autofill color from inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

#fc_frame.fc-widget-normal.default-position-fresh-desk {
  bottom: 35px;
}

#fc_frame.fc-widget-normal.default-position-chat-page-fresh-desk {
  bottom: 75px;
}
